
.chip {
    padding: 0 12px;
    height: 34px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .chip {
        padding: 0 16px;
        height: 44px;
    }
}
@media (min-width: 1200px) {
}
